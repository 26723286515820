import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
//import { AuthGuard } from 'src/app/shared/guard/auth.guard';
import { GuestGuard } from 'src/app/shared/guard/guest.guard';
//import { DeactivateGuardService } from 'src/app/shared/services/deactivate-guard.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { ResetComponent } from './reset/reset.component';
import { SsoComponent } from './sso/sso.component';
import { AuthLandingComponent } from './landing/landing.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  {
    path: 'landing',
    component: AuthLandingComponent,
    data: { title: 'Landing' },
    // canActivate:[GuestGuard],
    //canDeactivate:[DeactivateGuardService],
  },
  {
    path: 'login',
    component: AuthLandingComponent,
    data: { title: 'Login', modal: 'Login'},
    canActivate:[GuestGuard],
    //canDeactivate:[DeactivateGuardService],
  },
  {
    path: 'signup',
    component: AuthLandingComponent,
    data: { title: 'Signup', modal: 'Signup'},
    canActivate:[GuestGuard],
    //canDeactivate:[DeactivateGuardService],
  },{
    path: 'verify',
    component: AuthLandingComponent,
    data: { title: 'Verify', modal: 'Verify'},
    canActivate:[GuestGuard],
    //canDeactivate:[DeactivateGuardService],
  },
  {
    path: 'reset',
    component: AuthLandingComponent,
    data: { title: 'Reset', modal: 'Reset' },
    /* canActivate: [AuthGuard] */
  },
  {
    path: 'forgot',
    component: AuthLandingComponent,
    data: { title: 'Forgot Password', modal: 'Forget' },
  },
  {
    path: 'sso',
    component: SsoComponent,
    canActivate: [MsalGuard],
    data: { title: 'Check SSO' },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
