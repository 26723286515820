import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class ResetComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  resetPasswordForm = new FormGroup({});
  password: any="password";
  show = true;
  confirm_password: any="password";
  show_confirm_pass = true;
  passToken: any;
  showText: boolean = true;

  passwordStrength:number =0;
  strengthLabel=['Very Poor','Very Poor','Poor','Average','Average','Strong','Very Strong']

  
  constructor(private fb: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService, private toastr: ToastrService,private spinner:NgxSpinnerService, private activeModal: NgbActiveModal) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.passToken = params['tk'];
    });
  }

  ngOnInit(): void {
    this.initResetform();
    this.tokencheck();
  }

  tokencheck() {
    const tokenInput = {
      token: this.passToken
    }
    this.authService.verifyToken(tokenInput)
      .subscribe((resp: any) => {
        if (typeof resp == typeof "") {
          this.showText = true;
        }
        else {
          this.showText =false;
        }
      },(error:any)=>{
        this.showText = false;
        this.toastr.error(error.error)
      })
  }

  initResetform() {
    this.resetPasswordForm = this.fb.group({
      password: ['', [this.SpaceValidator, Validators.required,Validators.pattern('(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{7,}')]],
      password_confirmation: ['', [Validators.required,this.SpaceValidator]]
    }, { validator: this.comparePasswords });
    this.resetPasswordForm.get('password')?.valueChanges.subscribe((password)=>{    
      this.measureStrength(password)
    })
  }

  SpaceValidator(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
      return { required: true }
    }
    else {
      return null;
    }
  }

  get reset() { return this.resetPasswordForm.controls; }

  comparePasswords(group: FormGroup) {
    return group.controls.password.value === group.controls.password_confirmation.value ? null : { notSame: true }
  }

  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = false;
    } else {
      this.password = 'password';
      this.show = true;
    }
  }
  showConformPassword() {
    if (this.confirm_password === 'password') {
      this.confirm_password = 'text';
      this.show_confirm_pass = false;
    } else {
      this.confirm_password = 'password';
      this.show_confirm_pass = true;
    }
  }

  login() {
    this.activeModal.close();
    this.router.navigate(['auth/login'])
  }

  onFormSubmit() {
    if (this.resetPasswordForm.invalid) {
      return;
    }
    else{
      const data = {
        password: this.resetPasswordForm.value.password_confirmation,
      }
      this.spinner.show()
      this.authService.changepassword(data, this.passToken).subscribe((data: any) => {
        this.spinner.hide()
        if(data.msg){
          this.toastr.error(data.msg.includes("password recently") ? "Your new password cannot be same as any of your recent passwords. Please choose a new password.": data.msg)            
        }
        else if (data) {
          this.toastr.success('Password changed succesfully!')
          this.resetPasswordForm.reset();
          this.router.navigate(['auth/login'])
        }
      },(error:any)=>{this.spinner.hide()})
    }
  }
  measureStrength(pass: string) {  
    // bonus points for mixing it up  
    let variations:any = {  
      digits: /\d/.test(pass),  
      lower: /[a-z]/.test(pass),  
      upper: /[A-Z]/.test(pass),  
      nonWords: /\W/.test(pass), 
      minCharacterCount:/^.{8,}$/.test(pass),  
      maxCharacterCount:/^.{11,}$/.test(pass),  
    };  
    this.passwordStrength=0
    for (let check in variations) {  
      this.passwordStrength += (variations[check]) ? 1 : 0;  
    }  
    if(this.passwordStrength>4){
      this.passwordStrength = /^(?=.*[A-Za-z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{5,}$/.test(pass)? this.passwordStrength : 4;
    }
  }

}

