import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, NavigationEnd, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable,of } from 'rxjs';
import { catchError, map, take} from 'rxjs/operators';
import {Location} from '@angular/common';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}
@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private _router: Router,private auth:AuthService,private data:DataService,private _location:Location){
    // this._router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationEnd) {
    //    localStorage.setItem("href_data",btoa(window.location.href))
    //   }
    // })
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {    
    console.log(state.url);
    if(localStorage.getItem('token')) {
      return this.auth.verifyUserToken(localStorage.getItem('token')).pipe(
        take(1),
        map((res:any) => {
          if (res?.token) {
            this._router.navigate(['auth/landing']);
            return false;
          }else{
              return true;
          } 
        }),
        catchError((err) => {
          return of(true);
        })
      );
    }else{
      //const inFromBack = performance && performance.getEntriesByType( 'navigation' ).map( (nav:any) => nav.type ).includes( 'back_forward' )      
      // if(state.url.includes("login")){// || localStorage.getItem("href_data")==btoa(window.location.href) || inFromBack
      //   return of(true);
      // }else{
        //this._location.back();
        return of(true);
     // }
    }  
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  
  canLoad(
    route: Route|any,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(localStorage.getItem('token')) {
        return this.auth.verifyUserToken(localStorage.getItem('token')).pipe(
          take(1),
          map((res:any) => {
            if (res?.token) {
              this._router.navigate(['auth/landing']);
              return false;
            }else{
                return true;
            } 
          }),
          catchError((err) => {
            return of(true);
          })
        );
      }else{
        return of(true);
      }  
  }
  
}
