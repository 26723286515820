import { HttpClient } from '@angular/common/http';
import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me'; // Prod graph endpoint. Uncomment to use.
import * as textConfiguration from 'src/assets/static-text-configuration.json';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class SsoComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  profile:any = '';
  constructor(private http: HttpClient, 
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastrService: ToastrService,
    private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.getProfile();
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
        this.spinner.show();
        this.authService.getTokenfromSSO(profile).subscribe((result:any) => {
        this.spinner.hide();
        localStorage.setItem('token', result.token);
        localStorage.setItem('permission', JSON.stringify(result.user));
        this.toastrService.success('Successfully logged in');
        this.activeModal.close();
        this.router.navigate(['auth/landing']);
        }, (error) => {
          throw error;
        });
      });
  }

}
