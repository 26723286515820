import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { LoginComponent } from '../login/login.component';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  signupForm: FormGroup | any;
  signupFormVerification: FormGroup | any;

  show = true;
  submitted = false;
  password: any = 'password';

  activeStep = 1;
  useremailFromRoute:any;

  passwordRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,14}$";

//   (?=.*[a-z])  -- check lower case letter
// (?=.*[A-Z]) -- check upper case letter
// (?=.*\d) -- check one digit exists




  constructor(private authService: AuthService,
    private router: Router,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
     private data:DataService,
    public activeModal: NgbActiveModal,
    private route: ActivatedRoute,

    private modalService: NgbModal) { }

  ngOnInit(): void {
    if( this.router.url.indexOf('verify')>-1){
      this.activeStep = 2;

    }
    this.route.queryParamMap
  .subscribe((params) => {
    this.useremailFromRoute = params.get('email');
    this.createForm()

  }
);
   
   
    

    //kepler220@yopmail.com
  
  }





  createForm() {
    this.signupForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required]

    });
    this.signupFormVerification = this.fb.group({
      otp: ['', Validators.required],
      email: [this.useremailFromRoute?this.useremailFromRoute:'', Validators.required],
      password: ['', [Validators.required,Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d)(?=.*[@$!%*?&]).{8,14}$/)]],
      confirm_password: ['', Validators.required]

    });
    this.signupFormVerification.controls['email'].disable();

  }
  onOtpChange(event:any){
    console.log(event)
    if(event.length==4){
      this.signupFormVerification.patchValue({otp:event})
    }

  }


  get f2() {
    return this.signupFormVerification.controls;
  }

  get f() {
    return this.signupForm.controls;
  }
  signup(){
    this.showErrorwithTimer();
    if (this.signupForm.invalid) {
      return;
    }
    let payload = { first_name: this.signupForm.value.first_name,
      last_name: this.signupForm.value.last_name,
      email: this.signupForm.value.email }
    this.spinner.show();
    this.authService.signupuser(payload).subscribe((result: any) => {
      this.spinner.hide();
    this.submitted =false;
    this.openModel(payload.first_name+" "+payload.last_name)
   // this.toastrService.success("Your sign-up request is sent to Kepler Admin.Look out for an email confirmation on it!");

     
    }, (error: any) => {
      this.submitted = false;
      this.spinner.hide();
      this.toastrService.error(error?.error?.email[0]?error?.error?.email[0]:error);
      throw error;
    });

  }

  openModel(name?:any){
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(ConfirmModalComponent, { size: 'md', modalDialogClass: "modal-dialog-centered" });
		modalRef.componentInstance.name = name;
    modalRef.componentInstance.message = 'Your sign-up request is sent to Kepler Admin. Look out for an email confirmation on it!';


  }

//  createOtp(){
//   if (this.signupForm.invalid) {
//     return;
//   }
//   let payload = {email: this.signupForm.value.email }
//   this.authService.createotp(payload).subscribe((result: any) => {
//     this.spinner.hide();
//     this.submitted =false;
//     this.toastrService.success("Your sign-up request is sent to Kepler Admin.Look out for an email confirmation on it!");
//     this.modalService.dismissAll();

   
//   }, (error: any) => {
//     this.submitted = false;
//     this.spinner.hide();
//     this.toastrService.error(error);
//     throw error;
//   });



//  }

  verifyOtp(){
    this.showErrorwithTimer();
    if (this.signupFormVerification.invalid) {
      return;
    }
    let payload = { otp: this.signupFormVerification.value.otp,
      email: this.signupFormVerification.controls.email.value
    }
    this.spinner.show();
    this.authService.verifyotp(payload).subscribe((result: any) => {
      console.log(result)
      this.createUser();
     
    }, (error: any) => {
      this.submitted = false;
      this.spinner.hide();
      this.toastrService.error(error?.error?error?.error:JSON.stringify(error));
      throw error;
    });



  }
createUser(){
    let payload = { password: this.signupFormVerification.value.password,
      email: this.signupFormVerification.controls.email.value,
      confirm_password: this.signupFormVerification.value.confirm_password
    }
    this.authService.createuser(payload).subscribe((result: any) => {
      this.spinner.hide();
      console.log(result)
      this.toastrService.success("Successfully Signed in.");
      this.modalService.dismissAll();

     
    }, (error: any) => {
      this.submitted = false;
      this.spinner.hide();
      this.toastrService.error(error);
      throw error;
    });

  }
  showErrorwithTimer(){
    this.submitted = true;
    setTimeout(() => {
      this.submitted = false;

    }, 8000);
  }

  get checkconfirmpassword(){
    if(this.signupFormVerification.value.password==this.signupFormVerification.value.confirm_password){
      return true;
    }
    else{
      return false;
    }



  }
  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = false;
    } else {
      this.password = 'password';
      this.show = true;
    }
  }
}
