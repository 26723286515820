<app-header></app-header>
<div class="kep-card">
  <div class="kep-menu-card kep-menu-card-1">
    <div class="menu menu-tl mt-1">
      <div>
        <hr class="line-bottom" />
        <a
          (click)="navigateToPage('balancedscorecard/dashboard')"
          class="menu-links"
          >Balanced Scorecard</a
        >
        <span style="position: absolute; right: -40px; display: flex; gap: 5px">
          <p
            *ngIf="balancedScoreCardCounts?.positive_pulses > 0"
            class="positive-pulse"
          ></p>
          <p
            *ngIf="balancedScoreCardCounts?.negative_pulses > 0"
            class="negative-pulse"
          ></p>
        </span>
      </div>
      <div>
        <hr class="line-top" />
        <a (click)="navigateToPage('riskconvergence')" class="menu-links"
          >Delivery Risk</a
        >
        <span style="position: absolute; right: -40px; display: flex; gap: 5px">
          <p
            *ngIf="pulseCounts?.Risk?.positive_pulses > 0"
            class="positive-pulse"
          ></p>
          <p
            *ngIf="pulseCounts?.Risk?.negative_pulses > 0"
            class="negative-pulse"
          ></p>
        </span>
      </div>
      <div>
        <hr class="line-bottom" />
        <a (click)="navigateToPage('workforce/dashboard')" class="menu-links"
          >People Insights</a
        >
      </div>
      <div>
        <hr class="line-top" />
        <a
          (click)="navigateToPage('performanceInsights/hubble')"
          class="menu-links"
          >Hubble</a
        >
      </div>
    </div>
    <div class="incontent typewriter" style="margin-top: -10px">
      <div>
        <h6 class="mb-1" style="font-weight: 800; text-decoration: underline">
          PERFORMANCE INSIGHTS
        </h6>
        <p class="cssanimation sequence fadeInBottom mb-1">
          Develop unparalleled insights on IT performance with patent pending
          models
        </p>
        <ul style="margin-left: -20px; margin-bottom: 0">
          <li>
            Balanced scorecard for linked view on customer - financial -
            delivery - people performance indicators
          </li>
          <li>Structured risk management</li>
          <li>People Insights</li>
          <li>Qualitative feedback</li>
        </ul>
      </div>
    </div>
    <div class="kep-menu-card-content-image">
      <div class="menu-card-title-holder">
        <h6>Kaleidoscope</h6>
      </div>
    </div>
    <div class="attach-line"></div>
  </div>
  <div class="kep-menu-card kep-menu-card-2">
    <div class="menu menu-tr mt-1">
      <div>
        <hr class="line-bottom" />
        <a (click)="navigateToPage('okrconvergence')" class="menu-links"
          >OKR Convergence</a
        >
      </div>
      <div>
        <hr class="line-top" />
        <a
          (click)="navigateToPage('strategicRelevance/assessment-central')"
          class="menu-links"
          >Assessment Central</a
        >
      </div>
      <div>
        <hr class="line-bottom" />
        <a
          (click)="navigateToPage('strategicRelevance/governancect')"
          class="menu-links"
          >Governance Lighthouse</a
        >
      </div>
      <div>
        <hr class="line-top" />
        <a
          (click)="navigateToPage('strategicRelevance/maven')"
          class="menu-links"
          >Maven</a
        >
      </div>
    </div>
    <div class="incontent">
      <div>
        <h6 class="mb-1" style="font-weight: 800; text-decoration: underline">
          STRATEGIC RELEVANCE
        </h6>
        <p class="mb-1">
          Monitor IT effectiveness in delivering to business & corporate
          strategic objectives
        </p>
        <ul style="padding-left: 15px">
          <li>Convergence of IT delivery to OKRs</li>
          <li>Impact based ROI on IT investments</li>
          <li>
            Effectiveness of IT delivery governance & assurance check-posts
          </li>
          <li>Improvement insights</li>
        </ul>
      </div>
    </div>
    <div class="kep-menu-card-content-image">
      <div class="menu-card-title-holder">
        <h6>Polestar</h6>
      </div>
    </div>
    <div class="attach-line"></div>
  </div>
  <div class="kep-menu-card kep-menu-card-3">
    <div class="menu menu-tl">
      <div>
        <hr class="line-bottom" />
        <a (click)="navigateToPage('program')" class="menu-links"
          >Opportunity Workbench</a
        >
      </div>
      <div>
        <hr class="line-top" />
        <a (click)="navigateToPage('deal-structure')" class="menu-links"
          >Deal Structure</a
        >
      </div>
      <div>
        <hr class="line-bottom" />
        <a
          (click)="navigateToPage('challenge-workbench/challenge-statements')"
          class="menu-links text-break"
          >Challenge Workbench</a
        >
      </div>
      <div>
        <hr class="line-top" />
        <a (click)="navigateToPage('ideapitch')" class="menu-links"
          >Idea Pitch</a
        >
      </div>
    </div>
    <div class="incontent">
      <div>
        <h6 class="mb-1" style="font-weight: 800; text-decoration: underline">
          COGNITIVE SOURCING & INSIGHTS
        </h6>
        <p class="mb-1">
          Foster new deals and structure them with Kepler insights
        </p>
        <ul style="margin-left: -20px; margin-bottom: 0">
          <li>Draw from current performance levels</li>
          <li>Manage risks proactively from current footprint</li>
          <li>Run hackathons to crowd-source for ideas and opportunities</li>
          <li>Idea pitch for untapped opportunities</li>
        </ul>
      </div>
    </div>

    <div class="kep-menu-card-content-image">
      <div class="menu-card-title-holder">
        <h6>CoSInE</h6>
      </div>
    </div>
    <div class="attach-line"></div>
  </div>
  <div class="kep-menu-card kep-menu-card-4">
    <div class="menu menu-tr">
      <div>
        <hr class="line-bottom" />
        <a
          (click)="navigateToPage('delivery-accelerator/dashboard')"
          class="menu-links"
          >Delivery Accelerator</a
        >
      </div>
      <div>
        <hr class="line-top" />
        <a
          (click)="navigateToPage('confluence/teamplantracker')"
          class="menu-links"
          >Plan Central
        </a>
      </div>
      <div>
        <hr class="line-bottom" />
        <a (click)="navigateToPage('parade')" class="menu-links">Parade </a>
      </div>
      <div>
        <hr class="line-top" />
        <a (click)="navigateToSP()" class="menu-links">Community Of Practice</a>
      </div>
    </div>
    <div class="incontent">
      <div>
        <h6 class="mb-1" style="font-weight: 800; text-decoration: underline">
          COLLABORATION ACCELERATORS
        </h6>
        <p class="mb-1">
          Extract collaboration value from IT functions & stakeholder groups
        </p>
        <ul style="padding-left: 15px">
          <li>Empowered community of practice on knowledge assets</li>
          <li>
            Structured framework for IP development through applied innovation
          </li>
          <li>In-app review & personalisation zones for workgroups</li>
          <li>Cross-functional plan tracker</li>
        </ul>
      </div>
    </div>
    <div class="kep-menu-card-content-image">
      <div class="menu-card-title-holder">
        <h6>Confluence</h6>
      </div>
    </div>
    <div class="attach-line"></div>
  </div>
  <div class="kep-text">
    <img src="assets/images/kepler-icon.png" width="25px" />
  </div>
</div>
<app-footer></app-footer>
