<div class="loginStyle">
    <div class="col-12 row p-0">
        <div class="col-9 p-0 posRel">
            <a href="https://www.kairhos.com/" target="_blank">
                <div class="kairhoLogoCont">
                    <img src="assets/images/logo.svg" class="img-header" width="225">
                </div>
            </a>
            <img src="assets/images/login.png" style="width:100%; height: 100vh;border-radius: 0 150px 150px 0;">
            <div class="centered">
                <p class="fs-28 text-title-color lh-30">{{staticText.auth.reset_para1}}</p>
                <p class="fs-16 text-white">{{staticText.auth.reset_para2}}</p>
            </div>
        </div>
        <div class="col-3 p-0">
            <div class="row" class="">
                <div class="col-md-11 pt-5 posRel">
                    <div class="loginCard">
                        <a href="https://www.kepler.kairhos.com " class="text-decoration-none" target="_blank">
                            <div class="d-flex">
                                <img src="assets/images/kepler-ico.svg" class="img-header" width="36">
                                <span class="fs-36 ml-3 logo-color">{{staticText.name}}</span>
                            </div>
                        </a>
                        <h5 class="text-black mt-3">{{staticText.auth.reset_pwd}}</h5>
                        <div *ngIf="showText">
                            <form [formGroup]="resetPasswordForm" (ngSubmit)="onFormSubmit()" class="pt-3" autocomplete="off">
                                <div class="mb-3 fs-14">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>{{staticText.auth.new_pwd}}</mat-label>
                                        <mat-icon matPrefix class="my-icon">lock</mat-icon>
                                        <div class="d-flex">
                                            <input matInput placeholder="Password" [type]="password" autocomplete="new-password"
                                                formControlName="password">
                                            <span (click)="onClick()"
                                                class="far fa-eye form-control-feedback my-icon show-password"
                                                *ngIf="!show"></span>
                                            <span (click)="onClick()"
                                                class="fas fa-eye-slash form-control-feedback my-icon show-password"
                                                *ngIf="show"></span>
                                        </div>

                                    </mat-form-field>
                                    <mat-error
                                        *ngIf="resetPasswordForm.controls.password.errors && resetPasswordForm.controls.password.touched">
                                        <small class="text-danger"
                                            *ngIf="resetPasswordForm.controls.password.errors.required">{{staticText.auth.new_pwd_err_msg}}</small>
                                        <small class="text-danger"
                                            *ngIf="resetPasswordForm.controls.password.errors.minlength">
                                            {{staticText.auth.pwd_len_err_msg}}</small>
                                        <small class="text-danger"
                                            *ngIf="resetPasswordForm.controls.password.errors.maxlength">
                                            {{staticText.auth.pwd_max_err_msg}}</small>
                                        <small class="text-danger"
                                            *ngIf="resetPasswordForm.controls.password.errors.pattern">
                                            {{staticText.auth.pwd_validation_msg}}</small>
                                    </mat-error>
                                </div>
                                <div class="mb-3 fs-14">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>{{staticText.auth.confirm_pwd}}</mat-label>
                                        <div class="d-flex">
                                            <input matInput placeholder="Password" [type]="confirm_password" autocomplete="new-password"
                                                formControlName="password_confirmation">
                                            <span (click)="showConformPassword()"
                                                class="far fa-eye form-control-feedback my-icon show-password"
                                                *ngIf="!show_confirm_pass"></span>
                                            <span (click)="showConformPassword()"
                                                class="fas fa-eye-slash form-control-feedback my-icon show-password"
                                                *ngIf="show_confirm_pass"></span>
                                        </div>

                                        <mat-icon matPrefix class="my-icon">lock</mat-icon>
                                    </mat-form-field>
                                    <mat-error
                                        *ngIf="resetPasswordForm?.errors?.notSame || resetPasswordForm.get('password_confirmation').errors  && resetPasswordForm.controls.password_confirmation.touched">
                                        <small class="text-danger"
                                            *ngIf="resetPasswordForm.get('password_confirmation').errors?.required">
                                            {{staticText.auth.confirm_pwd_err_msg}}
                                            </small>
                                        <small class="text-danger"
                                            *ngIf="!resetPasswordForm.get('password_confirmation').errors?.required && resetPasswordForm.errors?.notSame">
                                            {{staticText.auth.pwd_mismatch_msg}}
                                            </small>
                                    </mat-error>
                                </div>
                                <div class="fs-14 fw-medium">Password Strength</div>
                                <div class="progressb progress-striped active" >
                                    <div id="jak_pstrength" class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"  [ngStyle]="{'width': (passwordStrength*(100/6))+'%'}" [ngClass]="{'danger': passwordStrength>0, 'warning':passwordStrength>2, 'success': passwordStrength>4}"> {{strengthLabel[passwordStrength]}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8">
                                        <p class="text-color fw-bold fs-10 mt-3 link-color cursor-pointer"
                                            (click)="login()">{{staticText?.buttons?.cancel_btn}}
                                        </p>
                                    </div>
                                    <div class="col-md-4">
                                        <button mat-button
                                            class="p-1 btn-color w-100 text-white py-2 letterspaceOne mb-2"
                                          [disabled]="resetPasswordForm.invalid" ><span
                                                class="fs-16">{{staticText?.buttons?.submit_btn}}</span></button>
                                    </div>
                                </div>

                            </form>
                        </div>

                        <div class="d-flex">
                            <div class="form-group text-center isexpiry" *ngIf="!showText">
                                <h6>{{staticText.auth.reset_oops}}</h6>
                                <a routerLink="/auth/login" routerLinkActive="active-link">{{staticText.auth.click_login}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>