<div class="loginStyle">
  <a href="https://www.kepler.kairhos.com " class="text-decoration-none" target="_blank">
    <div class="logo-holder">
      <img src="assets/images/kepler-ico.svg" class="img-header cursor-pointer" width="36">
      <span class="fs-36 ml-3 logo-color">{{staticText.name}}</span>
    </div>
  </a>
  <h5 class="text-black mt-3 fw-normal text-center">{{staticText.auth.login_heading}}</h5>
  <div class="row">
    <div class="col-md-12" *ngIf="enableUserNameForm">
      <form [formGroup]="userNameForm" class="pt-3 field-adjust">
        <div class="mb-3 fs-14">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{staticText.auth.username}}</mat-label>
            <input matInput placeholder="Username" formControlName="checkusername">
            <mat-icon matPrefix class="my-icon">person</mat-icon>
          </mat-form-field>
          <mat-error *ngIf="usrformSubmitted && userNameFormControls.checkusername.errors">
            <small class="text-danger"
              *ngIf="userNameFormControls.checkusername.errors.required">{{staticText.auth.username_err_msg}}</small>
          </mat-error>
        </div>
        <button mat-button class="p-1 btn-color w-100 text-white py-2 letterspaceOne mb-2"
          (click)="checkSSOlogin()"><span class="fs-16">{{staticText.auth.login_btn}}</span></button>
          
        <!-- <button mat-button class="p-1 w-100 py-2 letterspaceOne mb-2"><span class="fs-16">{{staticText.auth.sign_up_btn}}</span></button> -->
      </form>
    </div>
  </div>
  <div class="col-md-12" *ngIf="enableLoginForm" autocomplete="off">
    <div class="loginCard">
      <form [formGroup]="loginForm" class="pt-3 field-adjust" autocomplete="off">
        <div class="mb-3 fs-14">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{staticText.auth.username}}</mat-label>
            <input matInput placeholder="Username" formControlName="username" inputmode='none'>
            <mat-icon matPrefix class="my-icon">person</mat-icon>
          </mat-form-field>
          <mat-error *ngIf="submitted && f.username.errors">
            <small class="text-danger" *ngIf="f.username.errors.required">{{staticText.auth.username_err_msg}}</small>
          </mat-error>
        </div>

        <div class="mb-3 fs-14">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{staticText.auth.password}}</mat-label>
            <mat-icon matPrefix class="my-icon">lock</mat-icon>
            <div class="d-flex">
              <input matInput placeholder="Password" [type]="password" formControlName="password"
                autocomplete="new-password">
              <span (click)="onClick()" class="far fa-eye form-control-feedback show-password my-icon"
                *ngIf="!show"></span>
              <span (click)="onClick()" class="fas fa-eye-slash form-control-feedback show-password my-icon"
                *ngIf="show"></span>
            </div>
          </mat-form-field>
          <mat-error *ngIf="submitted && f.password.errors">
            <small class="text-danger" *ngIf="f.password.errors.required">{{staticText.auth.password_err_msg}} </small>
          </mat-error>
        </div>
        <div class="row">
          <div class="col-md-8">
            <p class="text-color fw-bold fs-14 mt-3 link-color cursor-pointer" (click)="forgot()">
              {{staticText.auth.forgotpwd_qn}}
            </p>
          </div>
          <div class="col-md-4">
            <button mat-button class="p-1 btn-color w-100 text-white py-2 letterspaceOne mb-2" (click)="login()"><span
                class="fs-16">{{staticText.auth.login_btn}}</span></button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-12" *ngIf="enableOtpForm" autocomplete="off">
    <div class="loginCard">
      <form [formGroup]="loginForm" class="pt-3 field-adjust" autocomplete="off">
        <div class="mb-3 fs-14">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{staticText.auth.username}}</mat-label>
            <input matInput placeholder="Username" formControlName="username" inputmode='none'>
            <mat-icon matPrefix class="my-icon">person</mat-icon>
          </mat-form-field>
          <mat-error *ngIf="submitted && f.username.errors">
            <small class="text-danger" *ngIf="f.username.errors.required">{{staticText.auth.username_err_msg}}</small>
          </mat-error>
        </div>

        <div class="mb-3 fs-14">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{staticText.auth.otp}}</mat-label>
            <mat-icon matPrefix class="my-icon">lock</mat-icon>
            <div class="d-flex">
              <input matInput placeholder="Enter OTP" [type]="number" formControlName="otp">
            </div>
          </mat-form-field>
          <mat-error *ngIf="submitted && f.password.errors">
            <small class="text-danger" *ngIf="f.password.errors.required">{{staticText.auth.otp_err_msg}} </small>
          </mat-error>
        </div>
        <div class="row">
          <div class="col-md-8">
            <!-- <p class="text-color fw-bold fs-14 mt-3 link-color cursor-pointer" (click)="resend()">
              {{staticText.auth.forgotpwd_qn}}
            </p> -->
          </div>
          <div class="col-md-4">
            <button mat-button class="p-1 btn-color w-100 text-white py-2 letterspaceOne mb-2" (click)="otpLogin()"><span
                class="fs-16">{{staticText.auth.login_btn}}</span></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>