import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ViewEncapsulation } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginComponent } from "../login/login.component";
import { ResetComponent } from "../reset/reset.component";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";
import { AuthService } from "src/app/shared/services/auth.service";
import { catchError, map, take } from "rxjs/operators";
import { of } from "rxjs";
import { Constants } from "src/app/shared/constant/constant";
import { ComingSoonDialog } from "src/app/shared/components/coming-soon/coming-soon-dialog";
import { MatDialog } from "@angular/material/dialog";
import { SignupComponent } from "../signup/signup.component";
import { ProjectService } from "src/app/shared/services/project.service";

@Component({
  selector: "app-auth-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AuthLandingComponent implements OnInit {
  //IDeactivateGuard
  pulseCounts: any;
  balancedScoreCardCounts: any = {};
  constructor(
    private router: Router,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private auth: AuthService,
    public dialog: MatDialog,
    private projectService: ProjectService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.data.subscribe((params) => {
      this.openModal(params?.modal);
    });
  }

  openModal(modal: any) {
    this.activeModal.close();
    if (modal == "Reset") {
      this.modalService.open(ResetComponent, {
        size: "md",
        modalDialogClass: "modal-dialog-centered",
      });
    } else if (modal == "Login") {
      this.modalService.open(LoginComponent, {
        size: "md",
        modalDialogClass: "modal-dialog-centered",
      });
    } else if (modal == "Signup") {
      this.modalService.open(SignupComponent, {
        size: "md",
        modalDialogClass: "modal-dialog-centered",
      });
    } else if (modal == "Verify") {
      this.modalService.open(SignupComponent, {
        size: "md",
        modalDialogClass: "modal-dialog-centered",
      });
    } else if (modal == "Forget") {
      this.modalService.open(ForgotPasswordComponent, {
        size: "md",
        modalDialogClass: "modal-dialog-centered",
      });
    }
  }

  async ngOnInit() {
    this.getPulsesCount();
  }

  getBalanceScorecardCounts() {
    const balanceScoreCardItems = ["Customer", "Delivery", "Finance", "People"];
    let positivePulses = 0;
    let negativePulses = 0;
    balanceScoreCardItems.forEach((item: any) => {
      positivePulses += this.pulseCounts[item].positive_pulses;
      negativePulses += this.pulseCounts[item].negative_pulses;
    });
    this.balancedScoreCardCounts["positive_pulses"] = positivePulses;
    this.balancedScoreCardCounts["negative_pulses"] = negativePulses;
  }

  getPulsesCount() {
    this.projectService.getPulseCounts().subscribe((resp: any) => {
      this.pulseCounts = resp;
      this.getBalanceScorecardCounts();
    });
  }

  navigateToPage(page: string) {
    this.router.navigateByUrl("/" + page);
  }

  navigateToSP() {
    window.open(Constants.SHAREPOINT_URL, "_blank");
  }

  ngOnDestroy(): void {}

  openComingSoon() {
    this.dialog.open(ComingSoonDialog);
  }
}
