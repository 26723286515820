import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest, EventMessage, EventType, AuthenticationResult } from '@azure/msal-browser';
import {  Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { DataService } from 'src/app/shared/services/data.service';
import { ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class LoginComponent implements OnInit {//IDeactivateGuard

  submitted = false;
  usrformSubmitted = false;
  domain: any;
  loginForm: FormGroup | any;
  userNameForm: FormGroup | any;
  enableLoginForm: boolean = false
  enableUserNameForm: boolean = true;
  enableOtpForm: boolean = false;

  year: any = new Date().getFullYear();
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  userNameFromCheck: any;
  password: any;

  show = true;

  staticText: any = (textConfiguration as any).default;
  
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalAuthService: MsalService, private data:DataService,
    private msalBroadcastService: MsalBroadcastService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.spinner.hide()
    this.domain = window.location.host;
    this.password = 'password';
    this.createForm();
    this.createUsernameForm();
    this.setLoginDisplay();
    this.msalAuthService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalAuthService.instance.setActiveAccount(payload.account);
        localStorage.setItem('sso-login', 'true');
        this.router.navigate(['auth/sso']);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.msalAuthService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
          // this.router.navigate(['auth/sso']);
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalAuthService.instance.getAllAccounts().length > 0;
  }

  createUsernameForm() {
    this.userNameForm = this.fb.group({
      checkusername: ['', Validators.required]
    });
  }

  createForm() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      otp: ['']
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  get userNameFormControls() {
    return this.userNameForm.controls;
  }

  checkSSOlogin() {
    this.usrformSubmitted = true;
    if (this.userNameForm.invalid) {
      return;
    }
    this.userNameFromCheck = this.userNameForm.value.checkusername;
    let payload = { username: this.userNameForm.value.checkusername }
    this.spinner.show();
    this.authService.loginSSO(payload).subscribe((result: any) => {
      this.spinner.hide();
      if (result == 1) {
        this.loginRedirect()
      }
      else if (result == 2) {
        this.loginForm.patchValue({
          username: this.userNameFromCheck
        });
        this.enableUserNameForm = false;
        this.enableLoginForm = true;
        this.enableOtpForm = false;
      }
      else if (result == 3) {
        this.loginForm.patchValue({
          username: this.userNameFromCheck
        });
        this.enableUserNameForm = false;
        this.enableLoginForm = false;
        this.enableOtpForm = true;

        let payload = {
          email: this.userNameFromCheck
        }
        this.spinner.show();
        this.authService.triggerOtp(payload).subscribe((result: any) => {
          this.spinner.hide();
          this.toastrService.success("OTP sent successfully...")
          this.loginForm.get('password').clearValidators();
          this.loginForm.get('otp').setValidators([Validators.required]);
          this.loginForm.get('password').updateValueAndValidity();
          this.loginForm.get('otp').updateValueAndValidity();
        })
      }
      else {
        this.toastrService.error(result);
      }
    }, (error: any) => {
      this.usrformSubmitted = false;
      this.spinner.hide();
      this.toastrService.error('Invalid username');
      throw error;
    });

  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    let payload = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password
    }
    this.spinner.show();
    localStorage.setItem('permission', JSON.stringify(payload));
    this.authService.login(payload).subscribe((result: any) => {      
      this.spinner.hide();
      localStorage.setItem('token', result.token);
      result.user['extra_detail']=result?.extra_detail;
      localStorage.setItem('permission', JSON.stringify(result.user));
      this.toastrService.success('Successfully logged in');
      this.activeModal.close();
      this.router.navigate(['auth/landing']);
    }, error => {
      this.submitted = false;
      this.spinner.hide();
      if(error.error){
        let errmsg = typeof error.error == typeof {} ? error.error[Object.keys(error.error)[0]] :error.error;
        this.toastrService.error(errmsg);
      }else{
        this.toastrService.error('Invalid username or password');
      }
      throw error;
    })
  }

  otpLogin() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    let payload = {
      email: this.loginForm.value.username,
      otp: this.loginForm.value.otp
    }
    this.spinner.show();
    localStorage.setItem('permission', JSON.stringify({
      email: this.loginForm.value.username
    }));
    this.authService.otplogin(payload).subscribe((result: any) => {      
      this.spinner.hide();
      localStorage.setItem('token', result.token);
      result.user['is_vendor'] = result.is_vendor;
      localStorage.setItem('permission', JSON.stringify(result.user));

      this.toastrService.success('Successfully logged in');
      this.activeModal.close();
      this.router.navigate(['auth/landing']);
    }, error => {
      this.submitted = false;
      this.spinner.hide();
      if(error.error){
        let errmsg = typeof error.error == typeof {} ? error.error[Object.keys(error.error)[0]] :error.error;
        this.toastrService.error(errmsg);
      }else{
        this.toastrService.error('Invalid username or password');
      }
      throw error;
    })
  }

  forgot() {
    this.activeModal.close();
    this.router.navigate(['/auth/forgot']);
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalAuthService.instance.getActiveAccount();

    if (!activeAccount && this.msalAuthService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalAuthService.instance.getAllAccounts();
      this.msalAuthService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalAuthService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalAuthService.loginRedirect();
    }
  }

  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = false;
    } else {
      this.password = 'password';
      this.show = true;
    }
  }

  navigateToPage(page: string){
    this.router.navigateByUrl('/'+page);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  // canExit(){
  //   // return true - navigate without confirmation
  //   // return false - show a confirm dialog before navigating away
  //   if(this.data.isRouteNavigate)
  //       return true;
  //   else
  //       return false;
  // }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHander(event:any) {
  //     // or directly false
  //     console.log("Sdf");
  //     event.preventDefault();

      
  // }
}


