<div class="loginStyle">
    <a href="https://www.kepler.kairhos.com " class="text-decoration-none" target="_blank">
      <div class="logo-holder">
        <img src="assets/images/kepler-ico.svg" class="img-header cursor-pointer" width="36">
        <span class="fs-36 ml-3 logo-color">{{staticText.name}}</span>
      </div>
    </a>
    <h5 class="text-black mt-3 fw-normal text-center">{{staticText.auth.forgotpwd_heading}}</h5>
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="forgotPasswordForm" class="pt-3">
            <div class="mb-3 fs-14">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{staticText.auth.username}}</mat-label>
                    <input matInput placeholder="Username" formControlName="username">
                    <mat-icon matPrefix class="my-icon">person</mat-icon>
                </mat-form-field>
                <mat-error
                    *ngIf="forgotPasswordForm.controls.username.errors && forgotPasswordForm.controls.username.touched">
                    <small class="text-danger"
                        *ngIf="forgotPasswordForm.controls.username.errors.required">{{staticText.auth.username_err_msg}}</small>
                </mat-error>
            </div>
            <button mat-button class="p-1 btn-color w-100 text-white py-2 letterspaceOne mb-2"
                (click)="forogtPassword()" [disabled]="!forgotPasswordForm.valid"><span
                    class="fs-16">{{staticText.buttons.submit_btn}}</span></button>
        </form>
      </div>
    </div>
</div>
