import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SsoComponent } from './sso/sso.component';
import { MatIconModule } from '@angular/material/icon';
import { ResetComponent } from './reset/reset.component';
import { AuthLandingComponent } from './landing/landing.component';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { SignupComponent } from './signup/signup.component';
import { NgOtpInputModule } from 'ng-otp-input';
//import { DeactivateGuardService } from 'src/app/shared/services/deactivate-guard.service';


@NgModule({
  declarations: [
    AuthLandingComponent,
    ForgotPasswordComponent,
    LoginComponent,
    SsoComponent,
    ResetComponent,
    SignupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    NgbModalModule,
    NgOtpInputModule
  ],
  providers: [
    NgbActiveModal,
  ]
  //providers: [DeactivateGuardService],
})
export class AuthModule { }
