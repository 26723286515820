
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm = new FormGroup({});
  username: any;
  user_details: any;
  staticText: any = (textConfiguration as any).default;

  constructor(private fb: FormBuilder, private router: Router, private authService: AuthService, private toastr: ToastrService, private spinner: NgxSpinnerService,
    private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.initForgotform();
  }

  initForgotform() {
    this.forgotPasswordForm = this.fb.group({
      username: ["", [Validators.required]],
    });
  }

  forogtPassword() {
    const data = {
      username: this.forgotPasswordForm.value.username,
    }
    this.spinner.show();
    this.authService.forgotPassword(data).subscribe((res: any) => {
      this.user_details = res[0].user;
      this.forgotPasswordLink();
    });
  }

  forgotPasswordLink() {
    this.spinner.show();
    const data = {
      username: this.user_details.username,
      email: this.user_details.email,
      base_path: window.location.origin + '/auth/reset/'
    }
    this.authService.getLinkPasswordResetLink(data).subscribe((res: any) => {
      this.user_details = res?.user;
      this.toastr.success('Password reset link sent to your email')
      this.forgotPasswordForm.reset();
      this.spinner.hide();
      this.activeModal.close();
      this.router.navigate(['/auth/landing'])
    });
  }
}

