<div class="loginStyle">
    <a href="https://www.kepler.kairhos.com " class="text-decoration-none" target="_blank">
      <div class="logo-holder">
        <img src="assets/images/kepler-ico.svg" class="img-header cursor-pointer" width="36">
        <span class="fs-36 ml-3 logo-color">{{staticText.name}}</span>
      </div>
    </a>
    <h5 class="text-black mt-3 fw-normal text-center">{{staticText.auth.sign_up_btn}}</h5>

    <div class="col-md-12" *ngIf="activeStep==1"  autocomplete="off">
      <div class="loginCard">
        <form [formGroup]="signupForm" class="pt-3 field-adjust" autocomplete="off">
          <div class="mb-3 fs-14">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{staticText.auth.first_name}}</mat-label>
              <input matInput placeholder="First Name" formControlName="first_name">
              <mat-icon matPrefix class="my-icon">person</mat-icon>
            </mat-form-field>
            <mat-error *ngIf="submitted && f.first_name.errors">
              <small class="text-danger" *ngIf="f.first_name.errors.required">{{staticText.auth.firstname_err_msg}}</small>
            </mat-error>
          </div>
          <div class="mb-3 fs-14">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{staticText.auth.last_name}}</mat-label>
              <input matInput placeholder="Last Name" formControlName="last_name">
              <mat-icon matPrefix class="my-icon">person</mat-icon>
            </mat-form-field>
            <mat-error *ngIf="submitted && f.last_name.errors">
              <small class="text-danger" *ngIf="f.last_name.errors.required">{{staticText.auth.lastname_err_msg}}</small>
            </mat-error>
          </div>
          <div class="mb-3 fs-14">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{staticText.auth.email}}</mat-label>
              <input matInput placeholder="Email" formControlName="email">
              <mat-icon matPrefix class="my-icon">person</mat-icon>
            </mat-form-field>
            <mat-error *ngIf="submitted && f.email.errors">
              <small class="text-danger" *ngIf="f.email.errors.required">{{staticText.auth.email_err_msg}}</small>
            </mat-error>
          </div>
          <!-- <div class="mb-3 fs-14">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{staticText.auth.password}}</mat-label>
              <mat-icon matPrefix class="my-icon">lock</mat-icon>
              <div class="d-flex">
                <input matInput placeholder="Password" [type]="password" formControlName="password"
                  autocomplete="new-password">
                <span (click)="onClick()" class="far fa-eye form-control-feedback show-password my-icon"
                  *ngIf="!show"></span>
                <span (click)="onClick()" class="fas fa-eye-slash form-control-feedback show-password my-icon"
                  *ngIf="show"></span>
              </div>
            </mat-form-field>
            <mat-error *ngIf="submitted && f.password.errors">
              <small class="text-danger" *ngIf="f.password.errors.required">{{staticText.auth.password_err_msg}} </small>
            </mat-error>
          </div> -->
          <div class="row">
            
            <div class="col-md-4">
              <button mat-button class="p-1 btn-color w-100 text-white py-2 letterspaceOne mb-2" (click)="signup()"><span
                  class="fs-16">{{staticText.auth.signup_btn}}</span></button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="col-md-12" *ngIf="activeStep==2"  autocomplete="off">
        <div class="loginCard">
          <form [formGroup]="signupFormVerification" class="pt-3 field-adjust" autocomplete="off">

             <div class="mb-3 fs-14">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{staticText.auth.email}}</mat-label>
                <input matInput placeholder="Email" formControlName="email">
                <mat-icon matPrefix class="my-icon">person</mat-icon>
              </mat-form-field>
              <mat-error *ngIf="submitted && f2.email.errors">
                <small class="text-danger" *ngIf="f2.email.errors.required">{{staticText.auth.email_err_msg}}</small>
              </mat-error>
            </div>
           
            <div class="mb-3 fs-14">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{staticText.auth.password}}</mat-label>
                <mat-icon matPrefix class="my-icon">lock</mat-icon>
                <div class="d-flex">
                  <input matInput placeholder="Password" [type]="password" formControlName="password"
                    autocomplete="new-password">
                  <span (click)="onClick()" class="far fa-eye form-control-feedback show-password my-icon"
                    *ngIf="!show"></span>
                  <span (click)="onClick()" class="fas fa-eye-slash form-control-feedback show-password my-icon"
                    *ngIf="show"></span>
                </div>
              </mat-form-field>
              <mat-error *ngIf="submitted && f2.password.errors">
                <small class="text-danger" *ngIf="f2.password.errors.required">{{staticText.auth.password_err_msg}} </small>
              </mat-error>
              <mat-error *ngIf="f2.password.errors">
                <small class="text-danger" *ngIf="f2.password.errors.pattern">Password requirments does not match </small>
              </mat-error>
            </div> 
            <div class="fs-14" >
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>{{staticText.auth.confirm_password}}</mat-label>
                  <mat-icon matPrefix class="my-icon">lock</mat-icon>
                  <div class="d-flex">
                    <input matInput placeholder="Confirm Password" [type]="password" formControlName="confirm_password"
                      autocomplete="new-password">
                    <span (click)="onClick()" class="far fa-eye form-control-feedback show-password my-icon"
                      *ngIf="!show"></span>
                    <span (click)="onClick()" class="fas fa-eye-slash form-control-feedback show-password my-icon"
                      *ngIf="show"></span>
                  </div>
                </mat-form-field>

                <mat-error *ngIf="submitted && f2.confirm_password.errors">
                  <small class="text-danger" *ngIf="f2.confirm_password.errors.required">{{staticText.auth.confirm_password_err_msg}} </small>
                </mat-error>
                <mat-error *ngIf="!checkconfirmpassword && !f2.confirm_password.errors">
                    <small class="text-danger" >{{staticText.auth.confirm_password_match_err_msg}} </small>
                  </mat-error>
              </div> 
              <div class="mb-3 fs-14" id="pass_requirment">
                <b>Password requirments</b>
                <div style="font-size: 10px;">MUST contain 8-14 characters</div>
                <div style="font-size: 10px;">MUST contain at least one uppercase letter</div>
                <div style="font-size: 10px;">MUST contain at least one lowercase letter</div>
                <div style="font-size: 10px;">MUST contain at least one number </div>
                <div style="font-size: 10px;">MUST contain at least one special character (!@#$%^&*~_-+";:<>/?)</div>
              </div> 



              <div class="mb-3 fs-14">
                <mat-label>{{staticText.auth.otp}}</mat-label>

                <ng-otp-input  [formCtrl]="otp" (onInputChange)="onOtpChange($event)"  [config]="{length:4,inputStyles:{'margin-right': '47px'},allowNumbersOnly:true}"></ng-otp-input>

              <mat-error *ngIf="submitted && f2.otp.errors">
                <small class="text-danger" *ngIf="f2.otp.errors.required">{{staticText.auth.otp_err_msg}}</small>
              </mat-error>
            </div> 
            <div class="row">
              
              <div class="col-md-4">
                <button mat-button class="p-1 btn-color w-100 text-white py-2 letterspaceOne mb-2" (click)="verifyOtp()" [disabled]="signupFormVerification.invalid||!checkconfirmpassword"><span
                    class="fs-16">{{staticText.auth.verify_btn}}</span></button>
              </div>
            </div>
          </form>
        </div>
      </div>



  </div>

  